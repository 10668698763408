import ENV from "../../constants/ENV";
import trap from "../../helpers/fp/decorators/trap";
import concatPaths from "../../helpers/fp/url/concatPaths";

const SOCKET_URL              = ENV.REACT_APP_SOCKET_URL;
export const THEME_ID         = ENV.REACT_APP_THEME_ID;
export const PUBLIC_PATH      = "public";
export const THEME_PATH       = "themes";
export const THEME_URL        = concatPaths([SOCKET_URL, THEME_PATH, THEME_ID]);
export const THEME_PUBLIC_URL = concatPaths([SOCKET_URL, PUBLIC_PATH, THEME_ID]);

const themeData = () => {
  try {
    const themeSTR = localStorage.getItem(THEME_ID);
    return themeSTR ? JSON.parse(themeSTR) : {};
  } catch (e) {
    localStorage.removeItem(THEME_ID);
    return null;
  }
}

const saveTheme = (theme) => ((theme
  ? localStorage.setItem(THEME_ID, theme)
  : localStorage.removeItem(THEME_ID)
), theme);

const fetchThemeData = trap(() => fetch(THEME_URL)
  .then(r => r.text())
  .then(saveTheme)
  .then(JSON.parse)
);

const themeApi = Object.freeze({
  themeData,
  fetchThemeData,
});

if (THEME_ID) (async () => {
  const replaceLogoPath = (path) => path && path.replace(/.+\./, "icon.");
  const themeData = themeApi.themeData() || await fetchThemeData();
  if (!themeData) return;
  const { icon } = themeData;
  if (!icon) return;
  const path = concatPaths([THEME_PUBLIC_URL, replaceLogoPath(icon)]);
  const linkIcon = document.head.querySelector("link[rel=icon]");
  linkIcon.href = path;
  const linkIcon2 = document.createElement("link");
  linkIcon2.rel = "icon";
  linkIcon2.href = path;
  document.head.appendChild(linkIcon2);
})();

export default themeApi;
