// CORE
import React, { Suspense, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// MUI
import { CssBaseline, ThemeProvider } from "@mui/material";

// STYLES
import "./styles/main.scss";
import { muiTheme } from "./assets/theme";
import "react-toastify/dist/ReactToastify.css";

// SLICK CAROUSEL STYLES`
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// HOOKS
import useTeams               from "./hooks/teams/useTeams";
import useCurrentTeamProjects from "./hooks/projects/useCurrentTeamProject";
import useSocket              from "./hooks/socket/useSocket";

// COMPONENTS
import AppRouter          from "./AppRouter";
import PageLoader         from "./components/shared/PageLoader";
import ScrollToTop        from "./components/common/ScrollToTop";
import ServerModeProvider from "./components/providers/ServerModeProvider";

import zero from "./helpers/fp/number/zero";
import inc  from "./helpers/fp/number/inc";

const App = () => {
  const { 0: key, 1: setKey } = useState(zero);
  window[Symbol.for("forceUpdate")] = () => setKey(inc);

  useTeams();
  useCurrentTeamProjects();
  useSocket();

  return (
    <ThemeProvider
      key={key}
      theme={muiTheme}
    >
      <CssBaseline />
      <ToastContainer
        autoClose={2000}
        closeOnClick
        draggable={false}
        hideProgressBar
        limit={3}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="top-center"
        rtl={false}
        theme="colored"
        style={{
          fontSize: "16px",
          maxWidth: "600px",
          width: "auto",
        }}
      />
      <Suspense fallback={<PageLoader />}>
        <BrowserRouter>
          <ScrollToTop />
          <ServerModeProvider>
            <AppRouter />
          </ServerModeProvider>
        </BrowserRouter>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
