// AXIOS
import axiosInstance, { getTrap } from "../../api/axiosInstance";

const getVersions = () => getTrap(`/k8s/versions`);

const getClusters = ({ teamId, projectId }) => getTrap(`/teams/${teamId}/projects/${projectId}/clusters`);

const createCluster = (data) => axiosInstance.post(`/clusters`, data);

const deleteCluster = ({ id }) => axiosInstance.delete(`/clusters/${id}`);

const stopCluster = ({ id }) => axiosInstance.put(`/clusters/${id}/stop`);
const startCluster = ({ id }) => axiosInstance.put(`/clusters/${id}/start`);

const deletePool = ({ id }) => axiosInstance.delete(`/pools/${id}`);

const createPool = (pool) => axiosInstance.post(`/pools`, pool);

const deleteNode = ({ id }) => axiosInstance.delete(`/nodes/${id}`);

const k8sService = Object.freeze({
  getVersions,
  getClusters,
  createCluster,
  deleteCluster,
  deletePool,
  deleteNode,
  createPool,
  stopCluster,
  startCluster
});

export default k8sService;
