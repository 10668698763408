import { complement } from "lodash/fp";
import concatPaths from "../../helpers/fp/url/concatPaths";
import getPathName from "../../helpers/fp/window/getPathName";

const CREATE_PATH         = "create";
const NEW_PATH            = "new";
const PAYMENT_METHOD_PATH = "payment-method";

const forgotPasswordRoute = "/reset-password";
const confirmEmailRoute = "/confirm-email";
const loginRoute = "/login";
const registrationsRoute = "/registrations";
const INVITE_ROUTE = "/invite";
const CONFIRM_ROUTE = "/confirm";
const REACTIVATE_ROUTE = "/reactivate";
const REFERRAL_ROUTE = "/referral";
const waitingList = "/waitinglist";
const maintenance = "/maintenance";
const AUTH_ROUTE = "/auth";
const ACCESSIBLE_UN_AUTH_ROUTES = Object.freeze([
  forgotPasswordRoute,
  confirmEmailRoute,
  loginRoute,
  AUTH_ROUTE,
  registrationsRoute,
  INVITE_ROUTE,
  waitingList,
  maintenance,
]);

const settingsRoute = "/settings";
const profileRoute = "/profile";
const deactivateRoute = "/deactivate";
const virtualMachineRoute = "/virtual-machines";
const databaseRoute = "/databases";
const appRoute = "/apps";
const virtualMachineNewRoute = "/virtual-machines/new";
const databaseNewRoute = "/databases/new";
const appNewRoute = concatPaths([appRoute, NEW_PATH]);
const projectItemRoute = "/projects/:projectId";
const projectsRoute = "/projects";
const projectsNewRoute = "/projects/new";
const paymentVerificationRoute = "/payment-verification";
const createTeamRoute = "/create-team";
const billingRoute = "/billing";
const NEEDS_ROUTE = "/needs";
const TICKETS_ROUTE = "/tickets";
const SUPPORT_ROUTE = TICKETS_ROUTE;
const DOCS_ROUTE = "/docs";
const SECRETS_ROUTE = "/secrets";

const VAULT_ROUTE = "/vault";

const KUBERNETES_ROUTE = "/clusters";


const INVITE_PATH = "invite";

const KUBERNETES_NEW_ROUTE = concatPaths([KUBERNETES_ROUTE, NEW_PATH]);
const TICKET_CREATE_ROUTE = concatPaths([TICKETS_ROUTE, CREATE_PATH]);

// helpers
const isAcceptNoRedirect = () => [CONFIRM_ROUTE].includes(getPathName());
const isAccessibleUnAuth = () =>
  ACCESSIBLE_UN_AUTH_ROUTES.some((pathName) =>
    getPathName().startsWith(pathName)
  );
const isNeedAuth = complement(isAccessibleUnAuth);

export {
  ACCESSIBLE_UN_AUTH_ROUTES,
  waitingList,
  maintenance,
  REACTIVATE_ROUTE,
  forgotPasswordRoute,
  confirmEmailRoute,
  loginRoute,
  settingsRoute,
  profileRoute,
  projectItemRoute,
  projectsRoute,
  virtualMachineRoute,
  databaseRoute,
  appRoute,
  VAULT_ROUTE,
  virtualMachineNewRoute,
  databaseNewRoute,
  appNewRoute,
  registrationsRoute,
  CONFIRM_ROUTE,
  paymentVerificationRoute,
  NEEDS_ROUTE,
  createTeamRoute,
  billingRoute,
  deactivateRoute,
  SUPPORT_ROUTE,
  DOCS_ROUTE,
  TICKETS_ROUTE,
  TICKET_CREATE_ROUTE,
  SECRETS_ROUTE,
  INVITE_ROUTE,
  projectsNewRoute,
  KUBERNETES_ROUTE,
  KUBERNETES_NEW_ROUTE,
  AUTH_ROUTE,
  REFERRAL_ROUTE,
  CREATE_PATH,
  NEW_PATH,
  PAYMENT_METHOD_PATH,
  INVITE_PATH,
  isAcceptNoRedirect,
  isAccessibleUnAuth,
  isNeedAuth,
};
