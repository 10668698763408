import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { IconLogo } from "../../../assets/icons";

import "./style.css";

// hooks
import useCurrentProject from "../../../hooks/projects/useCurrentProject";

// helpers
import concatPaths from "../../../helpers/fp/url/concatPaths";

// constants
import SVG from "../../common/SVG";
import { projectsRoute } from "../../../constants/routes/routes";
import themeApi, { THEME_ID, THEME_PUBLIC_URL } from "../../../api/theme";

const replaceLogoPath = (path) => path && path.replace(/.+\./, "logo.");

const Logo = ({
  className,
  ...props
}) => {
  const {
    0: theme,
    1: setTheme
  } = useState(themeApi.themeData)

  useEffect(() => {
    themeApi
      .fetchThemeData()
      .then(setTheme)
    ;
  }, []);

  const currentProject = useCurrentProject();
  const logo           = theme?.logo;

  const LOGO = THEME_ID && logo
    ? logo.endsWith(".svg")
      ? <SVG
          src={concatPaths([THEME_PUBLIC_URL, replaceLogoPath(logo)])}
          onLoadend={(svg) => svg.replace(/<svg /, `<svg fill="var(--color-white)" `)}
          className="logo"
        />
      : <img
          src={concatPaths([THEME_PUBLIC_URL, replaceLogoPath(logo)])}
          alt="logo"
          className="logo"
        />
    : <IconLogo />;

  return (
    <Box
      component={Link}
      to={concatPaths([projectsRoute, currentProject?._id])}
      aria-label="go to project"
      sx={{
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center'
      }}
      {...props}
      className={"logo-wrapper" + (className ? " " + className : "")}
    >
      {LOGO}
    </Box>
  );
};

export default Logo;
