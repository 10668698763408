
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { loginRoute } from "../constants/routes/routes";
import useAuthUser from "../hooks/user/useAuthUser";

const ProtectedRoute = ({ children, redirectPath = loginRoute }) => {
  const user = useAuthUser();

  // TODO: user should be null or object, but in this case it is empty object {}. WTF?
  if (!Object.keys(user).length) {
    return <Navigate to={redirectPath} replace />;
  }

  /* eslint-disable */
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
