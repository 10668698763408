/* eslint-disable prefer-promise-reject-errors */
import axios from "axios";
import {
  ACCESSIBLE_UN_AUTH_ROUTES,
  AUTH_ROUTE,
} from "../constants/routes/routes";

import accessToken from "../helpers/accessToken";
import trap from "../helpers/fp/decorators/trap";

import ENV from "../constants/ENV";

const baseURL      = ENV.REACT_APP_API_URL;
const tenantApiKey = ENV.REACT_APP_X_API_KEY;

const axiosInstance = axios.create({
  baseURL,
  mode: "no-cors",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.defaults.baseURL = baseURL;

export const useAccessToken = (config) => {
  const token = accessToken.value;
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
};

const useTenantApiKey = (config) => {
  if (tenantApiKey) {
    config.headers['x-api-key'] = tenantApiKey;
  }
  return config;
};

axiosInstance.interceptors.request.use(useAccessToken);
axiosInstance.interceptors.request.use(useTenantApiKey);

// eslint-disable-next-line no-promise-executor-return
const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

const RETRY_COUNT = 10;
const RETRY_TIME = 1000;
const retry = {
  count: RETRY_COUNT,
  urls: new Set(),
};

const ERR_NETWORK = "ERR_NETWORK";

const onCloseRetry = () => {
  retry.count = RETRY_COUNT;
};

axiosInstance.interceptors.request.use(
  (config) => {
    // Get the maintenance key from local storage
    const maintenanceKey = localStorage.getItem('maintenanceKey');

    // If the key exists, add it to the headers
    if (maintenanceKey) {
      config.headers['X-Maintenance-Key'] = maintenanceKey;
    }

    // Return the updated config
    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error?.response?.status;
    const config = error?.config;

    if (error?.code === ERR_NETWORK) return Promise.reject(error);

    if (!status && config) {
      if (retry.count > 0) {
        retry.urls.add(config.url);
        retry.count -= 1;
        config.data &&= JSON.parse(config.data);
        return delay(RETRY_TIME)
          .then(() => axiosInstance.request(config))
          .finally(() => {
            if (retry.urls.has(config.url)) {
              retry.urls.delete(config.url);
              if (retry.urls.size === 0) onCloseRetry();
            }
          });
      }

      if (retry.urls.has(config.url)) {
        retry.urls.delete(config.url);
        if (retry.urls.size === 0) onCloseRetry();
      }

      return Promise.reject(error);
    }

    if (retry.urls.has(config.url)) {
      retry.urls.delete(config.url);
      if (retry.urls.size === 0) onCloseRetry();
    }

    if (status === 401) {
      if (!ACCESSIBLE_UN_AUTH_ROUTES.includes(window.location.pathname)) {
        window.history.pushState({}, null, AUTH_ROUTE);
        if (window[Symbol.for("forceUpdate")])
          window[Symbol.for("forceUpdate")]();
        else
          setTimeout(() => {
            if (window[Symbol.for("forceUpdate")])
              window[Symbol.for("forceUpdate")]();
          }, 0);
      }
      accessToken.pop();
    }

    return Promise.reject(error);
  }
);

export const getTrap = trap((url) => axiosInstance.get(url));
export const createGetTrap = (url) => (path) => path ? getTrap(`${url}/${path}`) : getTrap(url);

export default axiosInstance;
