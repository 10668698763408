import { createTheme } from "@mui/material";

const getRootStyleProperty = (property) => window.getComputedStyle(document.documentElement).getPropertyValue(property);

export const muiTheme = createTheme({
  palette: {
    primary: {
      main         : getRootStyleProperty('--color-primary'),
      dark         : getRootStyleProperty('--color-primary-dark'),
      contrastText : getRootStyleProperty('--color-primary-light-4'),
    },
    secondary: {
      main         : getRootStyleProperty('--color-secondary'),
      dark         : getRootStyleProperty('--color-secondary-dark'),
      contrastText : getRootStyleProperty('--color-secondary-light-4'),
    },
    error: {
      main         : getRootStyleProperty('--color-dangery'),
      dark         : getRootStyleProperty('--color-dangery-dark'),
      contrastText : getRootStyleProperty('--color-dangery-light-4'),
    },
    gray: {
      main         : getRootStyleProperty('--color-gray'),
      dark         : getRootStyleProperty('--color-gray-dark'),
      contrastText : getRootStyleProperty('--color-gray-light-4'),
    },
    text: {
      primary   : getRootStyleProperty('--color-black'),
      disabled  : getRootStyleProperty('--color-gray-light'),
      secondary : getRootStyleProperty('--color-gray-dark-2'),
    }
  },
  typography: {
    variants:[
      {
        props: {
          bold: true,
        },
        style: {
          fontWeight: 600
        }
      }
    ],
    fontFamily        : "Proxima Nova,sans-serif",
    fontWeightRegular : 400,
    fontWeightMedium  : 500,
    fontWeightBold    : 600,
    htmlFontSize      : 16,
    h1: {
      fontSize   : "2.4rem",
      fontWeight : 300,
    },
    h2: {
      fontSize   : "2.5rem",
      fontWeight : 700,
      lineHeight : 1.3,
    },
    h3: {
      fontSize   : "2rem",
      fontWeight : 700,
      lineHeight : 1.3,
    },
    h4: {
      fontSize   : "1.5rem",
      fontWeight : 700,
      lineHeight : 1.3,
    },
    h5: {
      fontSize   : ".8rem",
      fontWeight : 700,
      lineHeight : 1.5,
    },
    h6: {
      fontSize   : "1rem",
      fontWeight : 700,
      lineHeight : 1.3,
    },
    subtitle1: {
      fontSize   : "1.5em",
      lineHeight : 1.2,
    },
    subtitle2: {
      fontSize   : "1.25em",
      lineHeight : 1.2,
    },
    body1: {
      fontSize   : "1.2rem",
      lineHeight : 1.5,
    },
    body2: {
      fontSize   : "1rem",
      lineHeight : 1.5,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          borderRadius : "var(--border-radius)",
        }
      },
      variants: [
        {
          props: {
            size: "small",
          },
          style: {
            fontSize: "1rem",
          },
        },
        {
          props: {
            size: "medium",
          },
          style: {
            fontSize   : "1em",
            lineHeight : "1em",
          },
        },
        {
          props: {
            error: true,
          },
          style: {
            color: "var(--color-dangery)",
          },
        }
      ]
    },
    MuiInput: {
      styleOverrides: {
        root: {
          flexBasis    : "var(--container-width-sm)",
          maxWidth     : "var(--container-width-sm)",
          width        : "100%",
          borderRadius : "var(--border-radius)",
        },
      },
      variants: [
        {
          props: {
            error: true,
          },
          style: {
            color: "var(--color-dangery)",
          },
        },
        {
          props: {
            color: "error",
          },
          style: {
            color: "var(--color-dangery)",
          },
        }
      ],
    },
    MuiContainer:{
      styleOverrides: {
        maxWidthLg: {
          maxWidth : "75rem",
        },
        maxWidthXl: {
          maxWidth : "90rem",
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius : "var(--border-radius)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize     : "1rem",
          lineHeight   : "1.125em!important",
          padding      : "0.75em 0.75em 0.75em!important",
          borderRadius : "var(--border-radius)!important",
        },
        inputSizeSmall: {
          fontSize : ".875rem",
        },
        inputSizeLarge: {
          fontSize : "1.5rem",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius : "var(--border-radius)",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root:{
          lineHeight : "1.125em!important",
          padding    : 0,
        },
        input: {
          padding: "0.25em 0.25em 0.25em!important",
        }
      },
      variants: [
        {
          props: { size: "small" },
          style: { fontSize   : ".875em" }
        },
        {
          props: { size: "medium" },
          style: { fontSize   : "1em" }
        },
        {
          props: { size: "large" },
          style: { fontSize : "1.25em" }
        }
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { size: "small" },
          style: { fontSize   : ".875rem" }
        },
        {
          props: { size: "medium" },
          style: { fontSize   : "1rem" }
        },
        {
          props: { size: "large" },
          style: {
            fontSize : "1.125rem",
            padding  : "0.75em 1.5em",
          }
        },
        {
          props: {
            color   : "error",
            variant : "contained",
          },
          style: {
            color           : "var(--color-white)",
            backgroundColor : "var(--color-dangery)",
          }
        },
        {
          props: {
            variant : "outlined",
            color   : "error",
          },
          style: {
            color           : "var(--color-dangery)",
            backgroundColor : "transparent",
            borderColor     : "currentColor",
          }
        },
        {
          props: {
            variant : "contained",
            color   : "gray",
          },
          style: {
            color           : "var(--color-gray-dark-3)",
            backgroundColor : "var(--color-gray-light-3)",
          }
        },
        {
          props: {
            variant : "outlined",
            color   : "gray",
          },
          style: {
            color           : "var(--color-gray)",
            backgroundColor : "transparent",
            borderColor     : "currentColor",
          }
        }
      ],
      defaultProps: {},
      styleOverrides: {
        root: {
          textTransform : "none",
          fontWeight    : 600,
          padding       : "0.625em 1.25em",
          lineHeight    : "1.25em",
          borderRadius  : "var(--border-radius)",
        },
        contained: {
          backgroundColor : "var(--color-primary)",
        },
        outlined: {
          borderStyle : "solid",
          borderColor : "currentColor",
          borderWidth : "var(--border-width)",
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": "Proxima Nova,sans-serif",
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor : "var(--color-secondary-dark-4)",
          color           : "var(--color-white)",
          fontSize        : ".8rem",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor : "inherit",
          color           : getRootStyleProperty("--color-white"),
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        backgroundColor: "var(--color-white)",
      },
    },
    MuiMenuItem: {
      variants: [
        {
          props: {
            color: "danger",
          },
          style: {
            color : "var(--color-dangery)",
            "&:hover": {
              backgroundColor : "var(--color-dangery-light-4)",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize        : "1rem",
          color           : "var(--color-black)",
          backgroundColor : "var(--color-white)",
          textAlign       : "center",
          display         : "flex",
          ":hover": {
            backgroundColor : "var(--color-primary-light-4)",
            zIndex          : 2,
          },
          ":is(:focus-visible, :focus, :hover, .Mui-selected)": {
            backgroundColor : "var(--color-primary-light-3)!important",
            zIndex          : 2,
          },
          ":focus-visible": {
            backgroundColor : "var(--color-primary-light-3)",
            zIndex          : 2,
          },
          ":focus": {
            backgroundColor : "var(--color-primary-light-3)",
            zIndex          : 2,
          },
          "&.Mui-disabled": {
            color           : "var(--color-gray-light)",
            backgroundColor : "var(--color-white)",
            cursor          : "not-allowed!important",
            opacity         : 1,
          },
        },
      },
    },
  },
});
