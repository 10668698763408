// AXIOS
import concatPaths from "../../helpers/fp/url/concatPaths";
import { getTrap } from "../axiosPublicInstance";

import { PROVIDER_PATH } from "../provider";

export const IMAGE_PATH = "images";

/**
  * @param {string} providerId
  */
const getImagesByProviderId = (providerId) => getTrap(concatPaths([PROVIDER_PATH, providerId, IMAGE_PATH]));


const imagesApi = Object.freeze({
  getImagesByProviderId
});

export default imagesApi;
